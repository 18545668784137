import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import the Close Icon

const LeaveDetailsModal = ({ open, onClose, rowData, modalTitle  }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
      {modalTitle}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {rowData && (
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1976d2", color: "white" }}>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>Description</TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>Total Leave</TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>Taken Leave</TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "white" }}>Balance Leave</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{rowData.Description}</TableCell>
                <TableCell>{rowData.Total}</TableCell>
                <TableCell>{rowData.Taken}</TableCell>
                <TableCell>{rowData.Balance}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveDetailsModal;
