import React, { useEffect } from "react";
import {
  Box,
  Chip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import AttendanceCard from "../../components/Cards/AttendanceCard";
import dayjs from "dayjs";
import { makeStyles } from "@material-ui/core/styles";
import { GetAttendanceCard } from "../../action/Attendance";

const useStyles = makeStyles((theme) => ({
  chip: {
    height: 32,
    margin: theme.spacing(0.5),
  },
  datePickerContainer: {
    width: "100px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
}));

const Attendance = () => {
  const [year, setYear] = React.useState(dayjs().year());
  const [month, setMonth] = React.useState(dayjs().format("MM"));
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentMonth = dayjs().format("MM");

  useEffect(() => {
    const selectedYear = year ? year : null;
    dispatch(GetAttendanceCard(selectedYear + "-" + month));
  }, [year, month, dispatch]);

  const handleChangeYear = (e) => {
    setYear(e.target.value);
  };

  const handleChangeMonth = (e) => {
    setMonth(e.target.value);
  };

  // List of months (descending order)
  const months = [
    { label: "December", value: "12" },
    { label: "November", value: "11" },
    { label: "October", value: "10" },
    { label: "September", value: "09" },
    { label: "August", value: "08" },
    { label: "July", value: "07" },
    { label: "June", value: "06" },
    { label: "May", value: "05" },
    { label: "April", value: "04" },
    { label: "March", value: "03" },
    { label: "February", value: "02" },
    { label: "January", value: "01" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mt: 1,
          mb: 1,
          mr: 1,
        }}
      >
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel>Year</InputLabel>
          <Select value={year} onChange={handleChangeYear} label="Year">
            {/* You can add dynamic years or keep static */}
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
            {/* Add more years if necessary */}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel>Month</InputLabel>
          <Select value={month} onChange={handleChangeMonth} label="Month">
            {months.map((chip) => (
              <MenuItem key={chip.value} value={chip.value}>
                {chip.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginLeft: 1,
          marginRight: 1,
          marginBottom: "70px",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <AttendanceCard />
        </Box>
      </Box>
    </Box>
  );
};

export default Attendance;
