import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox"; // Import Checkbox
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Loader from "../Utility/Loader";
import NotFound from "../Utility/NotFound";
import dayjs from "dayjs";

// Styled TableCell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 600,
    padding: "8px", // Increased padding for header
    height: "50px", // Increase header height
  },
  "&.MuiTableCell-body": {
    fontSize: 14,
    color: "black",
    padding: "4px", // Reduced padding for rows
    lineHeight: "1.2", // Tighten line spacing
  },
}));

// Styled TableRow to apply row-wide background color
const StyledTableRow = styled(TableRow)(({ bgcolor }) => ({
  backgroundColor: bgcolor || "inherit", // Row background color
  "& td, & th": {
    backgroundColor: "inherit",
    textAlign: "center",
    padding: "4px", // Apply consistent padding
    height: "36px", // Set minimum height for compact rows
  },
}));

export default function AttendanceCard() {
  const { responseBody, loading, msg } = useSelector(
    (state) => state.attendanceCard
  );

  const mappedItems = useMemo(() => {
    return (
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          maxWidth: "100vw", // Ensure table fits the screen
          overflowX: "auto",
        }}
      >
        <Table
          sx={{ tableLayout: "fixed", width: "100%" }} // Fixed table layout for consistent column widths
          aria-label="attendance table"
        >
          {/* Table Header */}
          <TableHead>
            {/* First Row: Grouped Headers */}
            <TableRow>
              <StyledTableCell align="center" rowSpan={2} sx={{ width: "15%" }}>
                Day
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={3}>
                Barcode
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={2}>
                Vehicle
              </StyledTableCell>
            </TableRow>

            {/* Second Row: Sub-Headers */}
            <TableRow>
              <StyledTableCell align="center" sx={{ width: "15%" }}>
                IN
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "10%" }}>
                Cnt
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "15%" }}>
                OUT
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "15%" }}>
                V.In
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: "15%" }}>
                V.Out
              </StyledTableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {responseBody.map((row, index) => (
              <StyledTableRow key={index} bgcolor={row.BackgroundColor}>
                {/* Day Column with Highlight */}
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ padding: "0px" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      backgroundColor: "#B5E8FF", // Consistent Day column highlight
                      padding: "4px",
                      borderRadius: 2,
                      width: "100%",
                      height: "50px",
                    }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      sx={{ color: "black", lineHeight: "1" }}
                    >
                      {new Date(row.Date).getDate()}
                    </Typography>
                    <Typography
                      fontSize={10}
                      fontWeight={600}
                      sx={{ color: "black", lineHeight: "1" }}
                    >
                      {row.Day.toString().substring(0, 3)}
                    </Typography>
                  </Box>
                </TableCell>

                {/* If LeaveType Exists */}
                {row.LeaveType !== "" ? (
                  <TableCell
                    align="center"
                    colSpan={5} // Span across the remaining cells
                    sx={{
                      fontWeight: "bold",
                      fontSize: "14px",
                      color: "black",
                    }}
                  >
                    {row.LeaveReason}
                  </TableCell>
                ) : (
                  <>
                    {/* IN Time */}
                    <TableCell align="center">
                      {row.InTime
                        ? dayjs(row.InTime, "hh:mm A").format("HH:mm")
                        : ""}
                    </TableCell>

                    {/* Cnt Column with Checkbox */}
                    <TableCell align="center">
                      {row.ContinuedStatus === "Y" ? (
                        <Checkbox checked={true} disabled={true} />
                      ) : (
                        "" // Empty when Cnt is not "Y"
                      )}
                    </TableCell>

                    {/* OUT Time */}
                    <TableCell align="center">
                      {row.OutTime
                        ? dayjs(row.OutTime, "hh:mm A").format("HH:mm")
                        : ""}
                    </TableCell>

                    {/* V.In Time */}
                    <TableCell align="center">
                      {row.VInTime
                        ? dayjs(row.VInTime, "hh:mm A").format("HH:mm")
                        : ""}
                    </TableCell>

                    {/* V.Out Time */}
                    <TableCell align="center">
                      {row.VOutTime
                        ? dayjs(row.VOutTime, "hh:mm A").format("HH:mm")
                        : ""}
                    </TableCell>
                  </>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [responseBody]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            overflow: "auto",
          }}
        >
          <Grid container rowSpacing={0.1}>
            {responseBody.length > 0 ? mappedItems : <NotFound text={msg} />}
          </Grid>
        </Box>
      )}
    </>
  );
}
