import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import LeaveBalance from "./LeaveBalance";
import Punctuality from "./Punctuality";
import NotEnteredLeave from "./NotEnteredLeave";
import LeaveSummery from "./LeaveSummery";
import {
  GetLeaveBalance,
  GetLeaveSummary,
  GetNotEnteredLeave,
  GetPunctuality,
} from "../../action/Leave";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import LeaveDetailsModal from "../../../src/components/Utility/LeaveDetailsModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const Leave = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [year, setYear] = React.useState(dayjs().year());
  const [selectedRow, setSelectedRow] = useState(null); // State for storing the selected row
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [selectedTab, setSelectedTab] = React.useState("LeaveBalance");
  const [modalTitle, setModalTitle] = useState("");
  const leaveBalanceData = useSelector(
    (state) => state.leaveBalance.responseBody
  );
  const isLoading = useSelector((state) => state.leaveBalance.loading);

  const maxYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => maxYear - index);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedTab(event.target.value); // Set selected tab based on dropdown selection
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    dispatch(GetLeaveBalance(year));
    dispatch(GetNotEnteredLeave(year));
    dispatch(GetPunctuality(year));
    dispatch(GetLeaveSummary(year));
  }, [dispatch, year]);

  // Calculate totals
  const totalLeave = leaveBalanceData.reduce(
    (sum, row) => sum + (parseFloat(row.Total) || 0),
    0
  );
  const totalTakenLeave = leaveBalanceData.reduce(
    (sum, row) => sum + (parseFloat(row.Taken) || 0),
    0
  );
  const totalBalanceLeave = leaveBalanceData.reduce(
    (sum, row) => sum + (parseFloat(row.Balance) || 0),
    0
  );

  const handleRowClick = (row) => {
    setSelectedRow(row); // Store the selected row data
// Set modal title based on leave type
if (row.Description === "Annual Leave") {
  setModalTitle("Annual Leave Details");
} else if (row.Description === "Casual Leave") {
  setModalTitle("Casual Leave Details");
} else if (row.Description === "Sick Leave") {
  setModalTitle("Sick Leave Details");
}


    setOpenModal(true); // Open the modal
    
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* Table for Leave Data */}

      <Paper elevation={1} sx={{ margin: 2, padding: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          Leave Balance Summary
        </Typography>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1976d2", color: "white" }}>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "14px", color: "white" }}
                >
                  Description
                </TableCell>

                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "14px", color: "white" }}
                >
                  Total
                </TableCell>

                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "14px", color: "white" }}
                >
                  Taken
                </TableCell>

                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "14px", color: "white" }}
                >
                  Balance
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {leaveBalanceData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white", // alternating row colors

                    "&:hover": {
                      backgroundColor: "#e3f2fd", // hover effect
                    },
                  }}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell sx={{ fontSize: "12px" }}>
                    {row.Description}
                  </TableCell>

                  <TableCell sx={{ fontSize: "12px" }}>{row.Total}</TableCell>

                  <TableCell sx={{ fontSize: "12px" }}>{row.Taken}</TableCell>

                  <TableCell sx={{ fontSize: "12px" }}>{row.Balance}</TableCell>
                </TableRow>
              ))}

              {/* Totals Row */}

              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Total
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  {totalLeave}
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  {totalTakenLeave}
                </TableCell>

                <TableCell sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  {totalBalanceLeave}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </Paper>

      {/* Year Dropdown */}
      <Box
        flex={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
          mt: 1,
          mb: 1,
          mr: 1,
        }}
      >
        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel>Year</InputLabel>
          <Select
            value={year}
            onChange={handleChangeYear}
            label="Year"
            size="large"
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 180 }}>
          <InputLabel>Table</InputLabel>
          <Select
            value={selectedTab}
            onChange={handleChange}
            label="Table"
            size="large"
          >
            <MenuItem value="LeaveBalance">Leave Balance</MenuItem>
            <MenuItem value="Punctuality">Punctuality</MenuItem>
            <MenuItem value="NotEnteredLeave">Not Entered Leave</MenuItem>
            <MenuItem value="LeaveSummery">Leave Summary</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Table for Leave Data */}
      <Paper elevation={3} sx={{ padding: 2 }}>
        <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: "bold" }}>
          {selectedTab === "LeaveBalance" && "Leave Balance Summary"}
          {selectedTab === "Punctuality" && "Punctuality Summary"}
          {selectedTab === "NotEnteredLeave" && "Not Entered Leave Summary"}
          {selectedTab === "LeaveSummery" && "Leave Summary"}
        </Typography>

        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <>
            {selectedTab === "LeaveBalance" && <LeaveBalance />}
            {selectedTab === "Punctuality" && <Punctuality />}
            {selectedTab === "NotEnteredLeave" && (
              <NotEnteredLeave selectedYear={year} />
            )}
            {selectedTab === "LeaveSummery" && <LeaveSummery />}
          </>
        )}
      </Paper>
      {/* Modal for displaying Leave Details */}
      {openModal && (
        <LeaveDetailsModal
          open={openModal}
          onClose={() => setOpenModal(false)} // Close the modal
          rowData={selectedRow} // Pass selected row data to the modal
          modalTitle={modalTitle}
        />
      )}
    </Box>
  );
};

export default Leave;
